import dynamic from 'next/dynamic';
// import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFetch } from 'services/useFetch';
import { APP_IMG, SHOW_COMMEMORATE } from 'src/constants';
import { LOGO_192 } from 'src/constants/layout';
import { FOOTER_DATA, NAV_DATA } from 'src/redux/navSlice';
// import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

// import { setPWA } from 'src/utils/set-pwa';
// import styled from 'styled-components';

const Header = dynamic(import('src/components/layouts/header'), { ssr: true });
const Footer = dynamic(import('src/components/layouts/footer'), { ssr: false });

const Layout = ({ children, ads, sessionSevId }) => {
  // const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const resMenu = await useFetch('/api/v1.0/navigations/menu-nav');
        const res = await Promise.all([
          useFetch('/api/footer'),
          useFetch('/api/v1.0/navigations/company-group')
        ]);
        const footerDetail = {
          menus: resMenu?.data,
          image: `${APP_IMG}/static/${LOGO_192}`,
          content: res[0]?.data?.data,
          partner: res[1]?.data
        };
        dispatch(NAV_DATA(resMenu?.data));
        dispatch(FOOTER_DATA(footerDetail));
      } catch (err) {
        console.error('==========> LAYOUT ERROR : ', err);
      }
    })();
  }, []);

  // useEffect(() => {
  //   setPWA();
  //   // // Detects if device is on iOS
  //   // const isIos = () => {
  //   //   const userAgent = window.navigator.userAgent.toLowerCase();
  //   //   return /iphone|ipad|ipod/.test(userAgent);
  //   // };
  //   // // Detects if device is in standalone mode
  //   // const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
  //   // console.log('#isInStandaloneMode', isInStandaloneMode);

  //   // // Checks if should display install popup notification:
  //   // if (isIos() && !isInStandaloneMode()) {
  //   //   setPopupIos({ showInstallMessage: true });
  //   // }
  // }, []);
  return (
    <>
      <div
        className='fix-head'
        style={{
          filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
        }}
      >
        <Header sessionSevId={sessionSevId} />
      </div>
      <div
        style={{
          filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
        }}
      >
        {children}
      </div>
      {/* <Pwa
        className='pwa'
        style={{
          filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
        }}
      >
        <div className='class-pwa-popup'>
          <div className='bg-pwa'>
            <img
              src='/static/images/logo-192.png'
              alt='logo-pwa'
              title='logo-pwa'
              width='100%'
              height='100%'
            />
            <div>
              <p>
                เพิ่ม <strong className='kom-text'>Tnews</strong>
              </p>
              <p>ลงในหน้าจอหลักของคุณ</p>
            </div>
            <div className='install-close'>
              <div
                className='add-button'
                //! DATALAYER
                onClick={() =>
                  sendDataLayerGlobal({
                    type: DATALAYER_TYPE.TRACK,
                    router: router?.pathname,
                    section: 'PWA',
                    data: {
                      title: 'ติดตั้ง PWA',
                      heading: 'ติดตั้ง PWA'
                    }
                  })
                }
              >
                ติดตั้ง
              </div>
              <div
                className='add-button-close'
                //! DATALAYER
                onClick={() =>
                  sendDataLayerGlobal({
                    type: DATALAYER_TYPE.TRACK,
                    router: router?.pathname,
                    section: 'PWA',
                    data: {
                      title: 'ปิด PWA',
                      heading: 'ปิด PWA'
                    }
                  })
                }
              >
                ปิด
              </div>
            </div>
          </div>
        </div>
      </Pwa> */}
      <div
        style={{
          filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
        }}
      >
        <Footer ads={ads} />
      </div>
    </>
  );
};
export default Layout;

// const Pwa = styled.div`
//   display: none;
//   position: fixed;
//   width: 100%;
//   z-index: 300;
//   top: 10px;
//   @media (max-width:480px) {
//     top: 100px;
//   }
//   .class-pwa-popup {
//     position: absolute;
//     right: 10px;
//     @media (max-width:480px) {
//       width: 288px;
//       max-width: 100%;
//       right: 50%;
//       transform: translate(50%, 0);
//     }
//     .bg-pwa {
//       display: flex;
//       background-color: rgba(20, 20, 20, 0.93);
//       padding: 8px 10px;
//       color: #fff;
//       align-items: center;
//       border-radius: 6px;
//       box-shadow: 0px 0px 13px 5px #ee242440;
//       img {
//         width: 50px;
//         padding: 0 5px;
//         vertical-align: middle;
//         margin-right: 10px;
//       }
//       p {
//         margin: 0;
//         font-size: 14px;
//         white-space: pre;
//         padding: 5px;
//         .kom-text {
//           color: #EE2424;
//         }
//         &:nth-child(2){
//           padding: 0 5px 5px 5px;
//           font-size: 12px;
//         }
//       }
//       .add-button,.add-button-close {
//         padding: 5px;
//         text-align: center;
//         white-space: pre;
//         cursor: pointer;
//       }

//       .install-close {
//         border-left: 1px solid #363636;
//         padding-left: 10px;
//         margin-left: 10px;
//       }

//       .add-button {
//         background-color: #000060;
//         font-weight: bold;
//         font-size: 14px;
//         border-radius:3px;
//         padding: 6px 8px;
//         color: #fff;
//         margin-bottom:5px;
//         &:hover{
//           background-color: #363636;
//         }
//       }

//       .add-button-close {
//         font-size: 12px;
//         &:hover{
//           color: red;
//         }
//       }
//     }
//   }
// `;
